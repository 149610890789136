import React from "react";
import { IconContext } from "react-icons";
import { FcGoogle } from "react-icons/fc";
import "../css/Temoignages.css";
import Commentaires from "./Commentaires";

const Temoignages = () => {
  return (
    <div className="carte-commentaires-fond w-full pt-4">
      <div
        id="temoignages_pos"
        className="temoignages-conteneur mx-auto py-[20px] px-[20px] container"
      >
        <h1 className="underline font-bold text-2xl mt-0 mx-6 text-[#5b8039da]">
          Témoignages
        </h1>
        <div className="conteneur-lien-avis-google">
          <a
            className="lien-avis-google text-base mx-4 mb-4 mt-2 italic underline text-[#4a692de1]"
            href="https://g.page/r/CWv_zmf65yFUEBM/review"
            target="_blank"
            rel="noreferrer"
          >
            Laissez un avis
            <IconContext.Provider
              value={{
                size: "1.3em",
                className: "mx-1",
              }}
            >
              <FcGoogle />
            </IconContext.Provider>
          </a>
        </div>
        <Commentaires />
      </div>
    </div>
  );
};

export default Temoignages;
