import React from "react";
import "../css/Infos.css";
import { IconContext } from "react-icons";
import { FaPhoneVolume, FaCcMastercard } from "react-icons/fa6";

import imgZoneChal from "../images/2021-11-10.jpg";

const Infos = () => {
  return (
    <div id="infos_pos" className="infos-conteneur w-full p-3">
      <h1 className="underline font-bold text-2xl mt-0 m-6 text-[#5b8039da]">
        Informations
      </h1>
      <div className="details-informations">
        <div className="conteneur-contact-horaires">
          <div className="cont-con-hor">
            <div className="conteneur-contact contour-zone">
              <h3 className="p-2 m-1 text-[#5f9bce]">Contact</h3>
              <p>Lisa</p>
              <a
                className="flex justify-center items-center italic underline lien-info text-[#4a692de1]"
                href="tel:0766142709"
              >
                <IconContext.Provider
                  value={{
                    size: "1em",
                    className: "icontel-infos",
                  }}
                >
                  <FaPhoneVolume />
                </IconContext.Provider>
                07-66-14-27-09
              </a>
              <p>30000 Nîmes</p>
            </div>
            <div className="conteneur-contact contour-zone">
              <p>7/7 jours de 11h à 00h</p>

              <p className="flex justify-center items-center">
                <IconContext.Provider
                  value={{
                    size: "2.5em",
                    className: "icon-cartecredit  text-[#5f9bce]",
                  }}
                >
                  <FaCcMastercard />
                </IconContext.Provider>
                Paiement par carte<br / >
                et espèces
              </p>
            </div>
          </div>
          <div className="conteneur-contact-infos ">
            <p>
              Livraison sur Nîmes et alentours SANS FRAIS à partir de 20€
              d'achat.
            </p>
            <p>PAS DE MAGASIN UNIQUEMENT EN LIVRAISON.</p>
            <p>
              Distributeur Français de produit CBD autorisé dans l'Union
              Européene.
            </p>
            <p>Interdit -18 ans et aux femmes enceintes. Ne pas fumer.</p>
          </div>
        </div>
        <div className="conteneur-carte-livraison">
          <p>Zone de livraison</p>
          <img
            className="zone-chalandise-img"
            src={imgZoneChal}
            alt="Zone de chalandise"
          />
        </div>
      </div>
    </div>
  );
};

export default Infos;
