import React, { useState, useEffect } from "react";
import FullScreenProduct from "./FullScreenProduct";
import "../css/ProductPage.css";


const ProductCardHuilesA = ({ product, onClick }) => {
  return (
    <div className="product-card" onClick={onClick}>
      <img src={product.image} alt={product.name} />
      <div className="product-info">
        <h3 className="text-base mt-2 m-2 font-bold text-[#5b8039b4]">
          {product.name}
        </h3>
        <p className="text-sm text-justify">{product.description}</p>
        <p className="text-sm  font-bold">{product.cbd}</p>
      </div>
    </div>
  );
};

const ProductPageHuilesA = () => {
  const [huilesAnim, setHuilesAnim] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductIndexCompte, setSelectedProductIndexCompte] =
    useState("");
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    const currentIndex = huilesAnim.findIndex((p) => p.name === product.name);
    setSelectedProductIndexCompte(currentIndex + 1 + "/" + huilesAnim.length);
  };

  const handleCloseFullScreen = () => {
    setSelectedProduct(null);
  };
  const handleNextProduct = () => {
    const currentIndex = huilesAnim.findIndex(
      (p) => p.name === selectedProduct.name
    );
    const nextIndex = (currentIndex + 1) % huilesAnim.length; // Index du produit suivant en bouclant à la fin de la liste
    const nextProduct = huilesAnim[nextIndex];
    setSelectedProduct(nextProduct);
    setSelectedProductIndexCompte(nextIndex + 1 + "/" + huilesAnim.length);
  };

  const handlePreviousProduct = () => {
    const currentIndex = huilesAnim.findIndex(
      (p) => p.name === selectedProduct.name
    );
    const previousIndex =
      (currentIndex - 1 + huilesAnim.length) % huilesAnim.length; // Index du produit précédent en bouclant au début de la liste si nécessaire
    const previousProduct = huilesAnim[previousIndex];
    setSelectedProduct(previousProduct);
    setSelectedProductIndexCompte(previousIndex + 1 + "/" + huilesAnim.length);
  };

  // Sample product data
  /* const products = [
    {
      name: "HUILE CBD ANIMAUX 5% - CHIEN & CHAT",
      description: `Huile de cbd à base d'huile de saumon , parfait pour la détente et l'apaisement des chats ainsi que les petits chiens.`,
      cbd: "Prix: 5% 19,90 €",
      image: g1,
    },
    {
      name: "HUILE CBD ANIMAUX 15% - CHIEN",
      description: `L'huile de cbd 15% à base d'huile de saumon, sera parfaite pour apaiser et détendre votre amis à 4 pattes.`,
      cbd: "Prix: 15% 26,90 €",
      image: g2,
    },
    {
      name: "HUILE CBD ANIMAUX PROTECT+ 10% - CHIEN",
      description: `La PROTECT+ lutte contre les douleurs inflammatoires et améliore la mobilité chez le chien.`,
      cbd: "Prix: 10% 22,90 €",
      image: g3,
    },
    {
      name: "HUILE CBD ANIMAUX D-STRESS 10% - CHIEN",
      description: `Combat le stress et l’anxiété grâce à des plantes naturelles et un extrait de chanvre riche en CBD.`,
      cbd: "Prix: 10% 22,90 €",
      image: g4,
    },

    // Add more product objects as needed
  ];*/
  useEffect(() => {
    const chargeFichier = async () => {
      setHuilesAnim(
        await fetch("/Produits/HuilesAnim.json").then((r) => r.json())
      );
    };
    chargeFichier();
  }, []);

  return (
    <div id="huilesani_pos" className="mt-20">
      <h1 className="underline font-bold text-2xl mt-0 m-6 text-[#5b8039da]">
        Liste des huiles pour animaux
      </h1>
      <div className="product-list">
        {huilesAnim.map((product, index) => (
          <ProductCardHuilesA
            key={index}
            product={product}
            onClick={() => handleProductClick(product)}
          />
        ))}
      </div>
      {selectedProduct && (
        <FullScreenProduct
          product={selectedProduct}
          productposition={selectedProductIndexCompte}
          onClose={handleCloseFullScreen}
          onNext={handleNextProduct}
          onPrevious={handlePreviousProduct}
        />
      )}
    </div>
  );
};

export default ProductPageHuilesA;
