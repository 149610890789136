import React from "react";
import "../css/Informations.css";

const Informations = () => {
  return (
    <div className="cartevisitefond w-full py-28 mt-5 ">
      <section className="container p-4 mx-auto max-w-7xl">
        <h3 className="text-xl mt-0 m-6 text-[#5b8039]">
          Explorez notre gamme de CBD, cultivé en France avec passion
        </h3>
        <p className="text-left">
          Chez nous, nous sommes animés par la passion pour le CBD de qualité.
          Notre objectif est de vous offrir une sélection variée de produits
          CBD, issus de cultures françaises, que nous avons choisis avec soin.
        </p>
        <h3 className="text-xl  mt-20  m-6 text-[#5b8039]">
          Des prix raisonnables pour tous
        </h3>
        <p className="text-left">
          Nous croyons que la qualité ne devrait pas être un luxe. C'est
          pourquoi nous nous efforçons de rendre nos produits CBD accessibles à
          tous. Nos prix compétitifs ne compromettent en rien la qualité que
          nous exigeons. Vous obtiendrez le meilleur des deux mondes.
        </p>
        <h3 className="text-xl  mt-20 m-6 text-[#5b8039]">
          Un accompagnement sur mesure pour vous
        </h3>
        <p className="text-left">
          Nous comprenons que chaque personne a des préférences et des besoins
          uniques lorsqu'il s'agit de CBD. C'est pourquoi nous offrons des
          conseils personnalisés, adaptés à vos goûts et à l'effet que vous
          recherchez. Notre équipe d'experts est là pour vous guider à chaque
          étape.
        </p>
        <h3 className="text-xl mt-20  m-6 text-[#5b8039]">
          Rapidité, fiabilité
        </h3>
        <p className="text-left">
          La satisfaction de nos clients est notre priorité absolue. Vous pouvez
          compter sur notre rapidité d'exécution et notre professionnalisme à
          chaque étape de votre expérience d'achat. Votre confiance est notre
          plus grande récompense.
        </p>
      </section>
    </div>
  );
};

export default Informations;
