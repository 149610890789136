import React from "react";
import { IconContext } from "react-icons";
import {
  FaPhoneVolume,
  FaCcMastercard,
  FaSquareFacebook,
  FaSquareGooglePlus,
} from "react-icons/fa6";
import "../css/Footer.css";

const Footer = () => {
  return (
    <div className="footer-conteneur-general w-full p-3 text-[#ffffff]">
      <div className="footer-conteneur w-full">
        <div className="conteneur-footer-partie">
          <p>Lisa Tranchesset</p>
          <a className="flex justify-center items-center" href="tel:0766142709">
            <IconContext.Provider
              value={{
                size: "1em",
                className: "icontel-infos",
              }}
            >
              <FaPhoneVolume />
            </IconContext.Provider>
            07-66-14-27-09
          </a>
          <p>30000 Nîmes</p>
          <p>E-mail: lisa.queen-cbd.fr</p>
          <p>SIREN: 901631937</p>
          <p>7/7 jours de 11h à 00h</p>
          <p className="flex justify-center items-center">
            <IconContext.Provider
              value={{
                size: "3em",
                className: " mr-2",
              }}
            >
              <FaCcMastercard />
            </IconContext.Provider>
            Paiement par carte <br />
            et espèces
          </p>
        </div>
        <div className="conteneur-footer-partie partie-central">
          <a
            className="flex justify-center items-center italic underline facebook-icon my-1 mx-3"
            href="https://www.facebook.com/profile.php?id=100072148352492"
            target="_blank"
            rel="noreferrer"
          >
            <IconContext.Provider
              value={{
                size: "2em",
                className: "facebook-icon-interieur",
              }}
            >
              <FaSquareFacebook />
            </IconContext.Provider>
          </a>
          <a
            className="flex justify-center items-center italic underline facebook-icon my-1 mx-3"
            href="https://g.co/kgs/KusKofu"
            target="_blank"
            rel="noreferrer"
          >
            <IconContext.Provider
              value={{
                size: "2em",
                className: "facebook-icon-interieur",
              }}
            >
              <FaSquareGooglePlus />
            </IconContext.Provider>
          </a>
        </div>
        <div className="conteneur-footer-partie">
          <p>Interdit -18 ans</p>
          <p>
            Déconseiller aux <br />
            femmes en enceintes
          </p>
          <p>Ne pas fumer</p>
          <p className="italic text-sm">Site web sans cookies</p>
        </div>
      </div>
      <div class="info-dev">
        <p>
          Site créé par
          <a
            href="https://nicolas-kwiatkowski.fr/"
            target="_blank"
            rel="noreferrer"
          >
            <span>Nicolas Kwiatkowski</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
