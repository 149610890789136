import React, { useState, useEffect } from "react";
import "./App.css";

import NavigationBar from "./composants/NavigationBar";
import Presentation from "./composants/Presentation";
import Informations from "./composants/Informations";

import ProductPageResine from "./composants/ProductPageResine";
import ProductPageFleurs from "./composants/ProductPageFleurs";
import ProductPageHuiles from "./composants/ProductPageHuiles";
import ProductPageHuilesA from "./composants/ProductPageHuilesA";
import Temoignages from "./composants/Temoignages";
import Infos from "./composants/Infos";
import Footer from "./composants/Footer";

function App() {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const navbar = document.getElementById("navbar");
      if (navbar && !navbar.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside); // Ajout de l'écouteur d'événements pour les appareils tactiles

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside); // Suppression de l'écouteur d'événements lors du démontage du composant
    };
  }, []);

  const closeNavbar = () => setExpanded(false);
  return (
    <div id="app" className="App">
      <header className="App-header"></header>
      <div id="navbar">
        <NavigationBar
          closeNavbar={closeNavbar}
          expended={expanded}
          setExpanded={setExpanded}
        />
      </div>
      <Presentation />
      <Informations />
      <div className="mx-auto py-[50px] px-[10px] container">
        <ProductPageResine />
        <ProductPageFleurs />
        <ProductPageHuiles />
        <ProductPageHuilesA />
      </div>
      <Temoignages />
      <div className="mx-auto py-[50px] container">
        <Infos />
      </div>
      <Footer />
    </div>
  );
}

export default App;
