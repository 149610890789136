import React, { useState } from "react";
import "../css/FullScreenProduct.css";

const FullScreenProduct = ({
  product,
  productposition,
  onClose,
  onNext,
  onPrevious,
}) => {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX && touchEndX) {
      const diff = touchStartX - touchEndX;
      if (diff > 50) {
        onNext();
      } else if (diff < -50) {
        onPrevious();
      }
    }
    setTouchStartX(null);
    setTouchEndX(null);
  };
  return (
    <div className="full-screen-product" onClick={onClose}>
      <div
        className="product-card-full-screen"
        onClick={stopPropagation}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <p className="position-produit">{productposition}</p>
        <button className="close-button font-mono" onClick={onClose}>
          X
        </button>
        <img src={product.image} alt={product.name} />
        <div className="mx-auto product-info2 max-w-xl flex flex-col justify-center items-center gap-2">
          <h2 className="text-xl m-2 font-bold text-[#5b8039da]">{product.name}</h2>
          <p className="text-left">{product.description}</p>
          <p>{product.cbd}</p>
        </div>
        <div className="navigation-buttons">
          <button onClick={onPrevious}>{"<<"}</button>
          <button onClick={onNext}>{">>"}</button>
        </div>
      </div>
    </div>
  );
};

export default FullScreenProduct;
