import React from "react";
import "../css/Presentation.css";
import image1 from "../images/2022-05-12.jpg";

const Presentation = () => {
  const scrollToInfos = () => {
    const element = document.getElementById("infos_pos");
    const element2 = document.getElementById("basic-navbar-nav");
    element2.classList.remove("show");
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  };

  return (
    <section
      id="acceuil_pos"
      className="presentation-conteneur fond-presentation w-full p-3"
    >
      <div className="flex flex-col max-w-fit mx-auto mt-20 mb-10 text-[#5b8039b4] presentation-conteneur-info">
        <h1 className="text-6xl font-bold mt-10 mb-6 textshadowtitre">
          Queen CBD Nîmes
        </h1>
        <div className="flex flex-row flex-wrap justify-around items-center">
          <div className="w-96 p-4">
            <img
              className="cartevisite-presentation"
              src={image1}
              alt="CBDQueen carte"
            />
          </div>
          <div className="m-4 flex flex-col justify-center items-center">
            <p className="text-2xl">Livraison et vente à domicile </p>
            <p className="text-2xl">Rapide et sans frais</p>
            <p className="text-2xl italic mb-10">(achat minimum 20€)</p>
            <p className="text-2xl italic">Nîmes et alentours</p>
            <p className="text-2xl italic">7/7 j de 11h à 00h</p>
            <p
              className="text-xl mt-2 italic underline lien-info text-[#4a692de1]"
              onClick={scrollToInfos}
            >
              Informations
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Presentation;
/* <div className="container mx-auto mb-10">
        <img className="w-full max-w-4xl mx-auto" src={image1} alt="Carte de visite" />        
      </div>*/
