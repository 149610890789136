import React, { useState, useEffect } from "react";
import FullScreenProduct from "./FullScreenProduct";
import "../css/ProductPageHuiles.css";


const ProductCardHuiles = ({ product, onClick }) => {
  return (
    <div className="product-card-h">
      <div className="product-info-h">
        <h3 className="text-base mt-2 m-2 font-bold text-[#5b8039b4]">
          {product.name}
        </h3>
        <p className="text-sm text-justify">
          <img src={product.image} alt={product.name} />
          {product.description}
          <br /> <br />
          {product.composition}
        </p>
        <p className="text-sm  font-bold">{product.price}</p>
      </div>
    </div>
  );
};

const ProductPageHuiles = () => {
  const [huiles, setHuiles] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductIndexCompte, setSelectedProductIndexCompte] =
    useState("");
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    const currentIndex = huiles.findIndex((p) => p.name === product.name);
    setSelectedProductIndexCompte(currentIndex + 1 + "/" + huiles.length);
  };

  const handleCloseFullScreen = () => {
    setSelectedProduct(null);
  };
  const handleNextProduct = () => {
    const currentIndex = huiles.findIndex(
      (p) => p.name === selectedProduct.name
    );
    const nextIndex = (currentIndex + 1) % huiles.length; // Index du produit suivant en bouclant à la fin de la liste
    const nextProduct = huiles[nextIndex];
    setSelectedProduct(nextProduct);
    setSelectedProductIndexCompte(nextIndex + 1 + "/" + huiles.length);
  };

  const handlePreviousProduct = () => {
    const currentIndex = huiles.findIndex(
      (p) => p.name === selectedProduct.name
    );
    const previousIndex = (currentIndex - 1 + huiles.length) % huiles.length; // Index du produit précédent en bouclant au début de la liste si nécessaire
    const previousProduct = huiles[previousIndex];
    setSelectedProduct(previousProduct);
    setSelectedProductIndexCompte(previousIndex + 1 + "/" + huiles.length);
  };

  // Sample product data
  /* const products = [
    {
      name: "Huile Sommeil au CBD - Symbiose",
      description:
        "Les huiles CBD de la gamme Symbiose sont étudiées pour cibler un état et apporter des effets puissants grâce à la combinaison de différentes plantes avec le Cannabidiol. La gamme symbiose renoue avec les propriétés naturelles des plantes. L’huile Sommeil au CBD allie les effets du chanvre, de la mélisse et de la verveine : des espèces végétales qui améliorent la qualité du sommeil. Le savoir-faire de cette marque française en matière de chanvre et de cannabidiol a particulièrement aidé à la confection du produit. De bonnes nuits en perspective. Cette huile Sommeil au CBD est composée d’une base d’huile coco issue de l’agriculture biologique qui facilite l’absorption du cannabidiol par l’organisme. Ce produit sans THC est fabriqué en France et contient 1 000 mg de CBD par fiole de 10 ml.",
      composition:
        "Composition: Huile De Coco MCT Biologique, Extrait de chanvre multi spectre avec du CBD Et d’autres Cannabinoïdes (CBG, CBN…), Mélatonine (20mg). Huile origine France",
      price: "Prix : 10%-22.90 € / 20%-34.90 €",
      image: g1,
    },
    {
      name: "Huile CBD mélatonine - Ma Petite Nature",
      description:
        "Dans chaque fiole de 10ml vous retrouvez du CBD multi spectre ainsi que 20mg de mélatonine afin de retrouver un cycle du sommeil apaisé. Cette huile s'adresse particulièrement aux personnes souffrant de décalage horaire ou qui souhaitent éviter les désagréments dus à une irrégularité des cycles du sommeil. Cette huile est disponible avec 1000mg et 2000mg de CBD pour 10ml ; à consommer par voie sublinguale, de préférence avant le coucher.",
      composition:
        "Composition: Huile De Coco MCT Biologique, Extrait De Chanvre Multi Spectre Avec Du CBD Et D’autres Cannabinoïdes (CBG, CBN…), Mélatonine (20mg). Huile origine France. Taux De CBD 10 % / 20 %",
      price: "Prix : 10%-22.90 € / 20%-34.90 €",
      image: g2,
    },
    {
      name: "HUILE ANTI-STRESS AU CBD - SYMBIOSE",
      description:
        "Les huiles CBD de la gamme Symbiose sont étudiées pour cibler un état et apporter des effets puissants grâce à la combinaison de différentes plantes avec le Cannabidiol. Grâce à sa gamme Symbiose, nous remettons les propriétés naturelles des plantes à l’honneur. En dosant méticuleusement du chanvre, de la camomille et de la verveine, ce fabricant français élabore une huile Anti-stress au CBD de grande qualité. De la sérénité liquide ! Le cannabidiol est d’ailleurs reconnu par les études scientifiques comme une molécule anxiolytique efficace. Une huile de coco issue de l’agriculture biologique a été choisie comme base de l’huile Anti-stress au CBD, car elle favorise les effets des plantes et du cannabidiol. Nature & CBD fabrique ce produit sans THC en France. Chaque flacon de 10 ml contient un dosage de 1 000 mg de cannabidiol.",
      composition:
        "Composition: Huile De Coco MCT Biologique, Extrait De Chanvre Multi Spectre Avec Du CBD Et D’autres Cannabinoïdes (CBG, CBN…), Camomille Matricaire, Verveine..",
      price: "Prix : 10% 36.90 €",
      image: g3,
    },
    {
      name: "HUILE CBD / CBG COCO MCT 10%",
      description:
        "L’huile au CBD / CBG Coco MCT 10% est un produit made in France ; elle est composée d’une huile coco MCT BIO et d'un extrait de chanvre multi spectre contenant 5% de CBD et 5% de CBG ; ces cannabinoïdes sont issus de variétés de chanvre autorisées. Le dosage à 10% s’adaptera à tous les consommateurs. Nature & CBD vous propose une huile originale pour découvrir le CBG en toute légalité, sans THC. Efficaces contre l’hyperactivité.",
      composition:
        "Composition: Huile Coco MCT Organique ù (Bio) De Qualité Alimentaire, Extrait De Chanvre Multi Spectre, CBD (5%), CBG (5 %)",
      price: "Prix : 10% 36.90 €",
      image: g4,
    },
    {
      name: "HUILE SÉRÉNITÉ AU CBD – SYMBIOSE",
      description:
        "Les huiles CBD de la gamme Symbiose sont étudiées pour cibler un état et apporter des effets puissants grâce à la combinaison de différentes plantes avec le Cannabidiol. Nous soulignons les propriétés naturelles des plantes grâce à sa gamme Symbiose. En faisant macérer du tilleul et de la lavande dans une huile coco issue de l’agriculture biologique, puis en y ajoutant un extrait de chanvre multi spectre avec des terpènes, du cannabidiol et du cannabigérol, la marque française réalise une huile Sérénité prompte à apaiser l’anxiété. L’huile coco permet aux principes actifs naturels d’être mieux assimilés par l’organisme. Cette réalisation de la marque française Nature & CBD ne contient pas de THC. L’huile Sérénité se présente en fioles de 10 ml incluant 500 mg de cannabidiol et 500 mg de cannabigérol.",
      composition:
        "Composition: Huile De Coco MCT Biologique, Extrait De Chanvre Multi Spectre Avec Du CBD, Du CBG Et D’autres Cannabinoïdes (CBN…), Tilleul, Lavande. Taux De CBD 10 %",
      price: "Prix : 10% 36.90 €",
      image: g5,
    },
    {
      name: "HUILE ANTI-DOULEUR AU CBD – SYMBIOSE",
      description:
        "Les huiles CBD de la gamme Symbiose sont étudiées pour cibler un état et apporter des effets puissants grâce à la combinaison de différentes plantes avec le Cannabidiol. Les produits de la gamme Symbiose sont composés dans le but d’apporter un bien-être spécifique grâce aux plantes. Cette huile Anti-douleur utilise des orties et de la reine des près macérés dans de l’huile de coco issue de l’agriculture biologique. Un extrait multi spectre de chanvre est ajouté, avec des terpènes, 10% de CBD et d’autres cannabinoïdes légaux. Ce savant mélange d’ingrédients naturels concourt à la diminution des inflammations et des douleurs. On trouve 1 000 mg de cannabidiol dans chaque fiole de 10 ml d’huile Anti-douleur. Le fabricant français Nature & CBD utilise une huile de coco qui améliore la biodisponibilité du CBD. Chaque goutte de cette préparation contient 5 mg de cannabidiol.",
      composition:
        "Composition: Huile De Coco MCT Biologique, Extrait De Chanvre Multi Spectre Avec Du CBD Et D’autres Cannabinoïdes (CBG, CBN…), Ortie, Reine Des Près. Taux De CBD 10 %.",
      price: "Prix : 10% 36.90 €",
      image: g6,
    },
    {
      name: "HUILE CBD COCO MCT",
      description:
        "L’huile au CBD Coco MCT sublinguale est un produit made in France ; elle est composée d’une huile coco MCT bio et d'un extrait de chanvre multi spectre contenant du CBD, du CBG et du CBN, obtenu à partir de variétés de chanvre autorisées. Nature & CBD vous propose une huile de qualité, légale et garantie sans THC. Cette huile est disponible avec 5%, 10%, 15%, 20% et 30% de CBD. Efficaces contre les douleurs. Les produits au CBD sont interdits à la vente aux mineurs de moins de 18 ans et déconseillés aux femmes enceintes. Avec chaque huile un embout spray est fourni ; 1 spray = 3 gouttes d'huile.",
      composition:
        "Composition: Huile De Coco MCT Biologique, Extrait De Chanvre Multi Spectre Avec Du CBD Et D’autres Cannabinoïdes (CBG, CBN…).",
      price:
        "Prix : 5% 19.90 € / 10% 34.90 € /15% 44.90 €/ 20% 54.90€ / 30% 69.90 €",
      image: g7,
    },

    // Add more product objects as needed
  ];*/
  useEffect(() => {
    const chargeFichier = async () => {
      setHuiles(await fetch("/Produits/Huiles.json").then((r) => r.json()));
    };
    chargeFichier();
  }, []);
  return (
    <div id="huiles_pos" className="mt-20">
      <h1 className="underline font-bold text-2xl mt-0 m-6 text-[#5b8039da]">
        Liste des huiles
      </h1>
      <div className="conteneur-huiles-infos">
        <div className="conteneur-huiles-infos-parties">
          <p>
            Une solution efficace et naturelle pour chacun de vos besoins grâce
            aux huiles de CBD françaises. Les bienfaits adaptés à toute la
            famille et même les enfants ! :
          </p>
          <p className="ml-12 mt-2">
            - Les insomnies ou les mauvaises nuits
            <br />
            - Le stress
            <br />
            - Les douleurs
            <br />
            - Les anxiétés / angoisses
            <br />
            - L'hyperactivité
            <br />
            - Les troubles de l'attention
            <br />- Agitation
          </p>
        </div>
        <div className="conteneur-huiles-infos-parties">
          <p>Les huiles sont également approuvées pour améliorer :</p>
          <p className="ml-12 mt-2">
            - La relaxation
            <br />
            - L'apaisement
            <br />
            - La détente musculaire
            <br />- La perte de l’appéti
          </p>
        </div>
      </div>
      <p className="medecin-info">
        N'hésitez pas à demandez conseil à votre médecin
      </p>
      <div className="product-list-h">
        {huiles.map((product, index) => (
          <ProductCardHuiles
            key={index}
            product={product}
            onClick={() => handleProductClick(product)}
          />
        ))}
      </div>
      {selectedProduct && (
        <FullScreenProduct
          product={selectedProduct}
          productposition={selectedProductIndexCompte}
          onClose={handleCloseFullScreen}
          onNext={handleNextProduct}
          onPrevious={handlePreviousProduct}
        />
      )}
    </div>
  );
};

export default ProductPageHuiles;
