import React, { useEffect, useState } from "react";
import FullScreenProduct from "./FullScreenProduct";
import "../css/ProductPage.css";

const ProductCardResine = ({ product, onClick }) => {
  return (
    <div className="product-card" onClick={onClick}>
      <img src={product.image} alt={product.name} />
      <div className="product-info">
        <h3 className="text-base mt-2 m-2 font-bold text-[#5b8039b4]">
          {product.name}
        </h3>
        <p className="text-sm text-justify">{product.description}</p>
        <p className="text-sm  font-bold">{product.cbd}</p>
      </div>
    </div>
  );
};

const ProductPageResine = () => {
  const [resines, setResines] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductIndexCompte, setSelectedProductIndexCompte] =
    useState("");
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    const currentIndex = resines.findIndex((p) => p.name === product.name);
    setSelectedProductIndexCompte(currentIndex + 1 + "/" + resines.length);
  };

  const handleCloseFullScreen = () => {
    setSelectedProduct(null);
  };
  const handleNextProduct = () => {
    const currentIndex = resines.findIndex(
      (p) => p.name === selectedProduct.name
    );
    const nextIndex = (currentIndex + 1) % resines.length; // Index du produit suivant en bouclant à la fin de la liste
    const nextProduct = resines[nextIndex];
    setSelectedProduct(nextProduct);
    setSelectedProductIndexCompte(nextIndex + 1 + "/" + resines.length);
  };

  const handlePreviousProduct = () => {
    const currentIndex = resines.findIndex(
      (p) => p.name === selectedProduct.name
    );
    const previousIndex = (currentIndex - 1 + resines.length) % resines.length; // Index du produit précédent en bouclant au début de la liste si nécessaire
    const previousProduct = resines[previousIndex];
    setSelectedProduct(previousProduct);
    setSelectedProductIndexCompte(previousIndex + 1 + "/" + resines.length);
  };

  // Sample product data

  /* const products = [
    {
      name: "Suprême Kétama",
      description: `Sa texture légère et polléneuse est très agréable pour les journées détentes. Il sera parfait pour vous relaxer en douceur.`,
      cbd: "CBD: 30%",
      image: g1,
    },
    {
      name: "Ice Amnésia",
      description:
        "Gourmand avec sa texture légèrement grasse, l'Ice Amnésia est parfait pour ce détendre en journée comme en soirée avec un bon goût de résine Amnésia.",
      cbd: "CBD: 44%",
      image: g2,
    },
    {
      name: "Résine Extra 0% THC",
      description:
        "Avec sa texture crémeuse et citronnée, elle sera parfaite pour votre tranquillité et vos repos.",
      cbd: "CBD: 50%",
      image: g3,
    },
    {
      name: "Suprême Gorilla",
      description:
        "Une texture proche du pollen avec son goût unique de gorilla, le Suprême Gorilla est une super résine pour ce relaxer.",
      cbd: "CBD: 50%",
      image: g4,
    },
    {
      name: "Suprême Amnésia",
      description:
        "La meilleure vente grâce à son effet puissant et relaxant tous comme sont goût ! Le tout muni d'une texture très agréable et facile à manipuler.",
      cbd: "CBD: 50%",
      image: g5,
    },
    {
      name: "Filtré",
      description:
        "Très fort en bouche mais également en effet , il est parfait pour les nuits difficiles. Il sera vous combler et vous faire profiter de ses bienfaits pour votre sommeil.",
      cbd: "CBD: 80%",
      image: g6,
    },
    {
      name: "Moorock Astéroïde",
      description:
        "Très forte en goût avec un effet puissant grace a son taux de cbd.",
      cbd: "CBD: 90%",
      image: g7,
    },
    // Add more product objects as needed
  ];*/

  useEffect(() => {
    const chargeFichier = async () => {
      setResines(await fetch("/Produits/Resines.json").then((r) => r.json()));
    };
    chargeFichier();
  }, []);
  return (
    <div id="resine_pos" className="mt-20 ">
      <h1 className="underline font-bold text-2xl my-0 mx-6 text-[#5b8039da]">
        Liste des résines :
      </h1>
      <div className="conteneur-produits-infos">
        <div className="conteneur-produits-infos-parties">
          <p>
            Les résines sont à 6,50 euros le gramme et la moonrocks à 7,50 euros
            le gramme. Les pochons sont disponibles au prix de 20 euros.
          </p>
        </div>
      </div>
      <div className="product-list">
        {resines.map((product, index) => (
          <ProductCardResine
            key={index}
            product={product}
            onClick={() => handleProductClick(product)}
          />
        ))}
      </div>
      {selectedProduct && (
        <FullScreenProduct
          product={selectedProduct}
          productposition={selectedProductIndexCompte}
          onClose={handleCloseFullScreen}
          onNext={handleNextProduct}
          onPrevious={handlePreviousProduct}
        />
      )}
    </div>
  );
};

export default ProductPageResine;
