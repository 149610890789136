import React from "react";
import Masonry from "react-masonry-css";

import "../css/Commentaires.css";

const StarRating = ({ rating }) => {
  // Convertit la note en un tableau d'étoiles
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      // Étoile pleine
      stars.push(<span>★</span>);
    } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
      // Étoile partiellement pleine (pour les demi-étoiles, par exemple)
      stars.push(<span className="half">⭐</span>); // Utilisez une icône ou un caractère différent pour représenter une demi-étoile
    } else {
      // Étoile vide
      stars.push(<span className="empty">☆</span>);
    }
  }

  return <div>{stars}</div>;
};

const CommentCard = ({ comment }) => (
  <div className="comment-card">
    <div className="comment-header">
      <h3>{comment.author}</h3>
      <div className="rating-and-date">
        <StarRating rating={comment.rating} />
        <small>{comment.date}</small>
      </div>
    </div>
    <p className="comment-content">{comment.text}</p>
  </div>
);

const Commentaires = () => {
  // Définit les points de rupture pour le layout de masonry
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    767: 1,
  };
  const commentsData = [
    {
      author: "Hajar Boufni",
      date: "il y a 3 mois",
      text: "Au top 😊 très réactif explique vraiment bien elle est vraiment agréable.",
      rating: 5,
    },
    {
      author: "damien san emeterio",
      date: "il y a 5 mois",
      text: "Service express livraison a domicile le dimanche.",
      rating: 5,
    },
    {
      author: "ali cia",
      date: "il y a 7 mois",
      text: "Aimable et disponible, la livraison est vraiment un plus. La qualité est au rendez vous j'ai été agréablement surprise. Large choix et prix abordables. Lisa conseille au cas par cas selon les goûts et l'effet recherché. C'est une valeur sûre. Je dors comme un bébé. Merci.",
      rating: 5,
    },
    {
      author: "Na Tamno",
      date: "il y a un an",
      text: "Lisa est super, elle connaît très bien ses produits qui sont de très bonne qualité. Et pour un prix vraiment raisonnable ! N'hésitez pas !",
      rating: 5,
    },
    {
      author: "Ja Vendeix",
      date: "il y a 2 ans",
      text: "Je recommande ! Très correct niveau prix et une bonne qualité de produits. Pour couronner le tout une vendeuse très agréable et a l'écoute🙏. Force à elle💪",
      rating: 5,
    },
    {
      author: "NAEL RSL",
      date: "il y a un an",
      text: "Très bon accueil et la vendeuse est vraiment à l’écoute de vos attentes, je recommande vraiment ( ps: les produits sont vraiment très bons)",
      rating: 5,
    },
    {
      author: "Roland Petit",
      date: "il y a 2 ans",
      text: "Une jeune femme très sympathique, professionnelle, bienveillante et de très bons conseils. Agréablement surpris par la qualité des produits.",
      rating: 5,
    },
    {
      author: "Natan Marchelidon",
      date: "il y a un an",
      text: "Des produits top, une carte variée, livré ! Que demander de plus ? Je recommande vivement 👍",
      rating: 5,
    },
    {
      author: "Doriane B",
      date: "il y a 2 ans",
      text: "Je suis ravie! Personne sérieuse, livraison rapide, et en plus très sympathique! Je recommande vivement !!",
      rating: 5,
    },
    {
      author: "Claire Defontaine",
      date: "il y a 2 ans",
      text: "Personne très agréable, je vous la recommande ! Produit correspondant à mes besoins ! Merci :)",
      rating: 5,
    },
    // Ajoutez d'autres commentaires ici
  ];

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {commentsData.map((comment) => (
        <CommentCard comment={comment} />
      ))}
    </Masonry>
  );
};

export default Commentaires;
