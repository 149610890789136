import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "../css/NavigationBar.css";
import imageLogo from "../images/Couronne CBD.png";
import { IconContext } from "react-icons";
import { FaPhoneVolume } from "react-icons/fa6";

const NavigationBar = ({ closeNavbar, expended, setExpanded }) => {
  const scrollToAcceuil = () => {
    const element = document.getElementById("acceuil_pos");
    /*  const element2 = document.getElementById("basic-navbar-nav");
   element2.classList.remove("show");
    const element3 = document.getElementById("nav-toggle-ham");
    element3.classList.add("collapsed");*/

    window.scrollTo({
      top: element.clientTop,
      left: 0,
      behavior: "smooth",
    });
  };
  const scrollToResine = () => {
    const element = document.getElementById("resine_pos");
    /* const element2 = document.getElementById("basic-navbar-nav");
    element2.classList.remove("show");*/
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  };
  const scrollToFleurs = () => {
    const element = document.getElementById("fleurs_pos");
    /*  const element2 = document.getElementById("basic-navbar-nav");
  element2.classList.remove("show");*/

    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  };
  const scrollToHuiles = () => {
    const element = document.getElementById("huiles_pos");
    /*  const element2 = document.getElementById("basic-navbar-nav");
  element2.classList.remove("show");*/
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  };
  const scrollToHuilesAni = () => {
    const element = document.getElementById("huilesani_pos");
    /*  const element2 = document.getElementById("basic-navbar-nav");
 element2.classList.remove("show");*/
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  };
  const scrollToTemoignages = () => {
    const element = document.getElementById("temoignages_pos");
    /* const element2 = document.getElementById("basic-navbar-nav");
    element2.classList.remove("show");*/
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  };
  const scrollToInfos = () => {
    const element = document.getElementById("infos_pos");
    /* const element2 = document.getElementById("basic-navbar-nav");
   element2.classList.remove("show");*/
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  };

  return (
    <Navbar
      expand="lg"
      fixed="top"
      expanded={expended}
      onToggle={setExpanded}
      className="custom-navbar navig-conteneur"
    >
      <Container>
        <img className="w-16 mr-8 logo" src={imageLogo} alt="CBDQueen Logo" />
        <Navbar.Brand
          className="liens nav-brand-extra text-left"
         
        >
          Queen CBD
          <br />
          <a className="flex justify-center items-center" href="tel:0766142709">
            <IconContext.Provider
              value={{
                size: "1em",
                className: "icontel-entete",
              }}
            >
              <FaPhoneVolume />
            </IconContext.Provider>
            07-66-14-27-09
          </a>
        </Navbar.Brand>
        <Navbar.Toggle
          id="nav-toggle-ham"
          aria-controls="basic-navbar-nav"
          className="navig-hamberger"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto mt-2">
            <Nav.Link
              className="liens"             
              onClick={() => {
                closeNavbar();
                scrollToAcceuil();
              }}
            >
              Accueil
            </Nav.Link>
            <Nav.Link
              className="liens"           
              onClick={() => {
                closeNavbar();
                scrollToResine();
              }}
            >
              Résines
            </Nav.Link>
            <Nav.Link
              className="liens"         
              onClick={() => {
                closeNavbar();
                scrollToFleurs();
              }}
            >
              Fleurs
            </Nav.Link>
            <Nav.Link
              className="liens"       
              onClick={() => {
                closeNavbar();
                scrollToHuiles();
              }}
            >
              Huiles
            </Nav.Link>
            <Nav.Link
              className="liens"        
              onClick={() => {
                closeNavbar();
                scrollToHuilesAni();
              }}
            >
              Animaux
            </Nav.Link>
            <Nav.Link
              className="liens"         
              onClick={() => {
                closeNavbar();
                scrollToTemoignages();
              }}
            >
              Témoignages
            </Nav.Link>
            <Nav.Link
              className="liens"             
              onClick={() => {
                closeNavbar();
                scrollToInfos();
              }}
            >
              Infos
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
