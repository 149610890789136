import React, { useState, useEffect } from "react";
import FullScreenProduct from "./FullScreenProduct";
import "../css/ProductPage.css";

const ProductCardFleurs = ({ product, onClick }) => {
  return (
    <div className="product-card" onClick={onClick}>
      <img src={product.image} alt={product.name} />
      <div className="product-info">
        <h3 className="text-base mt-2 m-2 font-bold text-[#5b8039b4]">
          {product.name}
        </h3>
        <p className="text-sm text-justify">{product.description}</p>
        <p className="text-sm  font-bold">{product.cbd}</p>
      </div>
    </div>
  );
};

const ProductPageFleurs = () => {
  const [fleurs, setFleurs] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductIndexCompte, setSelectedProductIndexCompte] =
    useState("");
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    const currentIndex = fleurs.findIndex((p) => p.name === product.name);
    setSelectedProductIndexCompte(currentIndex + 1 + "/" + fleurs.length);
  };

  const handleCloseFullScreen = () => {
    setSelectedProduct(null);
  };
  const handleNextProduct = () => {
    const currentIndex = fleurs.findIndex(
      (p) => p.name === selectedProduct.name
    );
    const nextIndex = (currentIndex + 1) % fleurs.length; // Index du produit suivant en bouclant à la fin de la liste
    const nextProduct = fleurs[nextIndex];
    setSelectedProduct(nextProduct);
    setSelectedProductIndexCompte(nextIndex + 1 + "/" + fleurs.length);
  };

  const handlePreviousProduct = () => {
    const currentIndex = fleurs.findIndex(
      (p) => p.name === selectedProduct.name
    );
    const previousIndex = (currentIndex - 1 + fleurs.length) % fleurs.length; // Index du produit précédent en bouclant au début de la liste si nécessaire
    const previousProduct = fleurs[previousIndex];
    setSelectedProduct(previousProduct);
    setSelectedProductIndexCompte(previousIndex + 1 + "/" + fleurs.length);
  };
  useEffect(() => {
    const chargeFichier = async () => {
      setFleurs(await fetch("/Produits/Fleurs.json").then((r) => r.json()));
    };
    chargeFichier();
  }, []);
  // Sample product data
  /* const products = [
    {
      name: "Strawberry",
      description: "Délicate mais puissante elle sera vous détendre en douceur",
      cbd: "",
      image: g1,
    },
    {
      name: "491 THCP",
      description:
        "Très puissante grâce à sa molécule , elle vous relaxera à 100% avec un très bon goût en bouche.",
      cbd: "THCP",
      image: g2,
    },
    {
      name: "Og Kush",
      description:
        "Puissante et efficace contre les insomnies, elle sera parfaite pour un effet garanti.",
      cbd: "",
      image: g4,
    },
    {
      name: "Gélato",
      description:
        "Petite douceur en journée comme en soirée, légère mais efficace.",
      cbd: "",
      image: g5,
    },
    // Add more product objects as needed
  ];*/

  return (
    <div id="fleurs_pos" className="mt-20">
      <h1 className="underline font-bold text-2xl my-0 mx-6 text-[#5b8039da]">
        Liste des fleurs :
      </h1>
      <div className="conteneur-produits-infos">
        <div className="conteneur-produits-infos-parties">
          <p>
            Les fleurs sont à 6,50 euros le gramme. Les pochons sont disponibles
            au prix de 20 euros.
          </p>
        </div>
      </div>
      <div className="product-list">
        {fleurs.map((product, index) => (
          <ProductCardFleurs
            key={index}
            product={product}
            onClick={() => handleProductClick(product)}
          />
        ))}
      </div>
      {selectedProduct && (
        <FullScreenProduct
          product={selectedProduct}
          productposition={selectedProductIndexCompte}
          onClose={handleCloseFullScreen}
          onNext={handleNextProduct}
          onPrevious={handlePreviousProduct}
        />
      )}
    </div>
  );
};

export default ProductPageFleurs;
